import React, { useState } from 'react';
import Step1 from './Stepper/step1';
import Step5 from './Stepper/step5';
import Review from './Stepper/review';
import AidRequest from './Stepper/aidRequest';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Logo from '../assets/idea-logo.png';

import 'bootstrap/dist/css/bootstrap.min.css';
import './Stepper/form.css';

import axios from 'axios';
import Alerts from '../helper/Alert';
import Step6 from './Stepper/step6';

export default function AidRequestOld(props) {
  console.log('NEW DATA', props.data?.applicant.ausername);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = React.useState({
    open: false,
    message: '',
    severity: '',
  });

  const [formData, setFormData] = useState({
    //other Details
    submittername: '',
    submitterphonenumber: '',
    // step1
    firstName: '',
    lastName: '',
    email: '',
    phonenumber: '',
    dob: Date,
    address: props.data?.address.addressone,
    city: props.data?.address.city,
    state: props.data?.address.state,
    district: props.data?.address.district,
    zip: props.data?.address.pincode,
    aadharNumber: props.data?.applicant.aadharcardnumber,
    aadharCardFront: props.data?.applicant.aadharCardFront,
    aadharCardBack: props.data?.applicant.aadharCardBack,
    marksheetsFile: '',
    passportSizePhotoFile: props.data?.applicant.passportSizePhotoFile,

    // step2
    fatherName: props.data?.parentorguardian.fathername,
    fatherOccupation: props.data?.parentorguardian.fatherprofession,
    motherName: props.data?.parentorguardian.mothername,
    motherOccupation: props.data?.parentorguardian.motherprofession,
    familyIncome: props.data?.parentorguardian.familyincomepm,
    familyCount: props.data?.parentorguardian.familyCount,
    proofOfIncomeFile: props.data?.parentorguardian.proofOfIncomeFile,
    // step3
    referralName: props.data?.indiavolunteer.ivname,
    referralPhone: props.data?.indiavolunteer.ivemail,
    referralEmail: props.data?.indiavolunteer.ivphone,
    referralRelationship: props.data?.indiavolunteer.relationtoapplicant,
    // step4
    volunteerName: props.data?.usavolunteer.usvname,
    volunteerEmail: props.data?.usavolunteer.email,
    volunteerPhone: props.data?.usavolunteer.usvphone,
    usaReferenceLetter: props.data?.usavolunteer.usaReferenceLetter,

    //aid request
    amountRequested: null,
    aidtype: '',
    reasonForRequesting: '',
    yearsofsupportisneeded: props.data?.aidinformation.yearsofsupportisneeded,
    multipleyears: props.data?.aidinformation.multipleyears,
    //step5
    institutionName: '',
    institutionAddress: '',
    institutionCity: '',
    institutionState: 'Tamil Nadu',
    institutionMobileNumber: '',
    institutionEmail: '',
    // bonafideCertificate:
    institutionZip: '',
    beneficiaryName: '',
    beneficiaryPhone: '',
    accountNumber: '',
    ifscCode: '',
    bankName: '',
    branchName: '',
    paymentAddress: '',
    // //step6
    // questions1: "",
    // familyCount: "",
  });

  const [files, setFiles] = useState([null, null, null]);
  console.log('NEW', files);

  const fileSelected = (index, event) => {
    const file = event.target.files[0];
    const updatedFiles = [...files];
    updatedFiles[index] = file;
    setFiles(updatedFiles);
  };

  const API_URL = process.env.React_App_Api;
  const postForm = async () => {
    try {
      setLoading(true);
      let dataobj = [];

      const fileData = new FormData();
      for (let i = 0; i < files.length; i++) {
        fileData.append('images', files[i]);
      }

      const folderName = `${formData.firstName}${
        formData.lastName
      }${formData.aadharNumber.slice(-4)}`;
      fileData.append('folderName', folderName);
      if (files.length > 0) {
        await axios.post(`${API_URL}/file/posts`, fileData).then((res) => {
          console.log('RES', res.data.files);
          dataobj = res.data.files;
          // dataobj.filesPath = uploadedUrls;
        });
      }
      const data = {
        reviewcomments: 'string',
        committeenotes: '',
        financecommitteenotes: 'string',
        username: 'string',
        submittername: 'string',
        submitterphonenumber: formData.submitterphonenumber,
        comments: '',
        status: 'requested',
        applicant: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          dob: formData.dob,
          aadharcardnumber: formData.aadharNumber,
          appemail: formData.email,
          phonenumber: formData.phonenumber,

          aadharCardFront: formData.aadharCardFront,
          aadharCardBack: formData.aadharCardBack,
          marksheetsFile: dataobj[0],
          passportSizePhotoFile: formData.passportSizePhotoFile,
        },
        address: {
          addressone: formData.address,
          city: formData.city,
          state: formData.state,
          district: formData.district,
          pincode: formData.zip,
        },
        questions: {
          answer: 'string',
        },
        parentorguardian: {
          fathername: formData.fatherName,
          fatherprofession: formData.fatherOccupation,
          mothername: formData.motherName,
          motherprofession: formData.motherOccupation,
          familyincomepm: formData.familyIncome,
          familyCount: formData.familyCount,
          proofOfIncomeFile: formData.proofOfIncomeFile,
        },
        aidinformation: {
          amtrequested: formData.amountRequested,
          reasonforrequesting: formData.reasonForRequesting,
          aidtype: formData.aidtype,
        },
        institutiondetails: {
          name: formData.institutionName,
          address: formData.institutionAddress,
          mobileNumber: formData.institutionMobileNumber,
          email: formData.institutionEmail,
          city: formData.institutionCity,
          state: formData.institutionState,
          pincode: formData.institutionZip,
          website: 'string',
          bonafideCertificate: dataobj[2],
        },
        paymentmethod: {
          ifsccode: formData.ifscCode,
          accountnumber: formData.accountNumber,
          bankname: formData.bankName,
          branchname: formData.branchName,
          branchAddress: formData.paymentAddress,
          beneficiaryname: formData.beneficiaryName,
          beneficiaryphone: formData.beneficiaryPhone,
        },
        indiavolunteer: {
          ivname: formData.referralName,
          ivphone: formData.referralPhone,
          ivemail: formData.referralEmail,
          relationtoapplicant: formData.referralRelationship,
        },
        usavolunteer: {
          usvname: formData.volunteerName,
          usvphone: formData.volunteerPhone,
          email: formData.volunteerEmail,
          usaReferenceLetter: dataobj[1],
        },
      };
      // await axios.put(`${API_URL}/aid/recreate/${props.data?._id}`, data);
      await axios.post(`${API_URL}/aid/insert`, data);
      setAlert({
        open: true,
        message: 'Form submitted successfully',
        severity: 'success',
      });
    } catch (error) {
      if (error.response.data?.statusCode === 409) {
        return setAlert({
          open: true,
          message: error.response.data?.error,
          severity: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
      } else {
        console.log('ERROR', error);
      }
      console.log(error);
      // setAlert({
      //   open: true,
      //   message: 'Something went wrong , Please try again',
      //   severity: 'error',
      // });
      // setTimeout(() => {
      //   window.location.reload();
      // }, 5000);
    } finally {
      setLoading(false); // Set loading state to false when form submission completes (whether success or error)
      window.location.reload();
    }
  };

  // const onChange = (e) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  //   console.log(formData.submittername);
  // };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  console.log(formData);
  console.log(formData.submittername);
  console.log(formData.submitterphonenumber);

  const handleNext = () => {
    console.log(activeStep);
    console.log(formData);
    console.log(errors);
    return setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const steps = [
    {
      label: 'Applicant Details',
      description: (
        <Step1
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
          handleNext={handleNext}
          data={props.data}
          fileSelected={fileSelected}
          files={files}
        />
      ),
    },

    {
      label: 'Aid Request',
      description: (
        <AidRequest
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
          handleNext={handleNext}
          handleBack={handleBack}
          data={props.data}
          fileSelected={fileSelected}
          files={files}
        />
      ),
    },
    {
      label: 'Instutution Details',
      description: (
        <Step5
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
          handleNext={handleNext}
          handleBack={handleBack}
          data={props.data}
          fileSelected={fileSelected}
          files={files}
        />
      ),
    },
    {
      label: 'Bank Details',
      description: (
        <Step6
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
          handleNext={handleNext}
          handleBack={handleBack}
          data={props.data}
          fileSelected={fileSelected}
          files={files}
        />
      ),
    },

    {
      label: 'Review',
      description: (
        <Review
          loading={loading}
          formData={formData}
          setFormData={setFormData}
          handleBack={handleBack}
        />
      ),
    },
  ];

  const [activeStep, setActiveStep] = React.useState(0);

  const handleReset = () => {
    setActiveStep(0);
  };
  const handleCloseAlert = () => {
    setAlert({
      open: false,
      message: '',
      severity: '',
    });
  };

  return (
    <Box sx={{ p: 2, md: '' }}>
      <Alerts
        open={alert.open}
        message={alert.message}
        severity={alert.severity}
        onClose={handleCloseAlert}
      />
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Typography sx={{ fontSize: '23px', fontWeight: 'bold' }}>
          <img
            src={Logo}
            alt="logo"
            width={'37'}
            height={'37'}
            // style={{ marginRight: "40px" }}
          />{' '}
          IDEA Scholarship Application - 2024{' '}
          <img
            src={Logo}
            alt="logo"
            width={'37'}
            height={'37'}
            style={{ marginRight: '40px' }}
          />
        </Typography>
        {/* <img
          src={Logo}
          alt="logo"
          width={"50"}
          height={"50"}
          style={{ marginRight: "40px" }}
        /> */}
      </Box>

      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === 3 ? (
                  <Typography variant="caption">Last step</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              {step.description}
              <Box sx={{ mb: 2 }}>
                {/* <Button
                  disabled={index === 0}
                  variant="outlined"
                  onClick={handleBack}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Back
                </Button> */}
                {index === 4 ? (
                  <Box sx={{ display: 'flex' }}>
                    <Button
                      variant="outlined"
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Back
                    </Button>{' '}
                    <Button
                      variant="contained"
                      color="success"
                      // onClick={modelBox}
                      onClick={postForm}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Review and Submit
                    </Button>
                  </Box>
                ) : (
                  // <Button
                  //   variant="contained"
                  //   onClick={handleNext}
                  //   sx={{ mt: 1, mr: 1 }}
                  // >
                  //   {index === steps.length - 1 ? "Finish" : "Continue"}
                  // </Button>
                  ''
                )}
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )}
    </Box>
  );
}
