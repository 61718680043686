import React from "react";
import { Container, Typography, Button, Box } from "@mui/material";
import GifImage from "./assets/Errors/Server.png";
const ServerErrorPage = () => {
  // React.useEffect(() => {
  //   const timer = setTimeout(() => {
  //     window.location.reload(); // Reload the page after 5 seconds
  //   }, 5000);

  //   return () => clearTimeout(timer); // Clear the timer on component unmount
  // }, []);
  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 5 }}>
      <Box sx={{ textAlign: "center", mx: "auto", mb: 4 }}>
        <img
          src={GifImage}
          alt="GIF"
          style={{ width: "300px", height: "200px" }}
        />
      </Box>

      <Typography
        variant="h1"
        align="center"
        gutterBottom
        style={{ fontSize: "55px", fontWeight: "bold", mt: 10 }}>
        500 - Internal Server Error
      </Typography>
      <Typography variant="body1" align="center" gutterBottom sx={{ mt: 1 }}>
        Oops! Something went wrong on our end.
      </Typography>
      <Typography variant="body1" align="center" gutterBottom>
        Please try again later.
      </Typography>
      {/* <Container sx={{ mt: 7, textAlign: 'center' }}>
        <Button variant="contained" color="primary" to="/">
          Go to Home
        </Button>
      </Container> */}
    </Container>
  );
};

export default ServerErrorPage;
