import * as React from 'react';
import Grid from '@mui/material/Grid';
import Item from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import { Box, Button } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import PhoneNumberField from '../../helper/Form/PhoneNumber';

// Define Yup schema for validation
const schema = yup.object().shape({
  volunteerName: yup
    .string()
    .required('Volunteer Name is required')
    .matches(
      /^[A-Za-z.\s]+$/,
      'This filed must contain only alphabetic characters, dots, and spaces'
    )
    .max(30, ' cannot exceed 30 characters'),
  volunteerEmail: yup
    .string()
    .email('Invalid email')
    .required('Volunteer Email is required'),
  volunteerPhone: yup.string().required('Volunteer Phone is required'),

  // referenceLetter: yup.mixed().notRequired(),
});

export default function Step4({
  formData,
  setFormData,
  handleNext,
  handleBack,
}) {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: formData,
  });

  const onSubmit = (data) => {
    console.log('Form Data:', data);
    setFormData(data);
    handleNext();
  };

  return (
    <>
      <Grid container spacing={2} p={2} sx={{ display: 'flex' }}>
        <Grid item md={3} sm={12}>
          <Item>
            <Controller
              name="volunteerName"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  id="outlined-textarea"
                  label={
                    <label>
                      Volunteer Name{' '}
                      <span style={{ color: 'orangered' }}> *</span>
                    </label>
                  }
                  placeholder="Enter Volunteer Name"
                  size="small"
                  className="responsive-form"
                  error={!!error}
                  helperText={error ? error.message : ''}
                  sx={{ width: 280, maxWidth: '100%' }}
                />
              )}
            />
          </Item>
        </Grid>
        <Grid item md={3} sm={12}>
          <Item>
            <Controller
              name="volunteerEmail"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  id="outlined-textarea"
                  label={
                    <label>
                      Volunteer Email{' '}
                      <span style={{ color: 'orangered' }}> *</span>{' '}
                    </label>
                  }
                  placeholder="eg:abc123@example.com"
                  size="small"
                  className="responsive-form"
                  error={!!error}
                  helperText={error ? error.message : ''}
                  sx={{ width: 300, maxWidth: '100%' }}
                />
              )}
            />
          </Item>
        </Grid>
        <Grid item md={3} sm={12}>
          <PhoneNumberField
            control={control}
            name="volunteerPhone"
            label={
              <label>
                Volunteer Mobile Number
                <span style={{ color: 'orangered' }}>*</span>
              </label>
            }
            error={errors.volunteerPhone}
          />
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex' }}>
        {/* <Button variant="outlined" onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
          Back
        </Button> */}
        <Button
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          sx={{ mt: 1, mr: 1 }}
        >
          Continue
        </Button>
      </Box>
    </>
  );
}
