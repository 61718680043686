import React from "react";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true, error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          {this.props.fallback}
          {console.error("ErrorBoundary FallBack", this.state.error.toString())}
          {/* <p>{this.state.error.toString()}</p> */}
          {/* <p>{this.state.errorInfo.componentStack}</p> */}
        </div>
      );
    }
    return this.props.children;
  }
}
