import { Box, Button, FormHelperText, Grid, Typography } from "@mui/material";

import "bootstrap/dist/css/bootstrap.min.css";
import TextField from "@mui/material/TextField";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import "./form.css";
import { useForm, Controller } from "react-hook-form";
import { Form } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomAmountInput from "../../helper/Form/Amount";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import FormLabel from "@mui/material/FormLabel";
import { useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const FILE_SIZE = 500 * 1024;

const schema = yup.object().shape({
  amountRequested: yup.number().required("Amount Requested is required"),
  aidtype: yup.string().required("Request Aid Type is required"),
  reasonForRequesting: yup
    .string()
    .required("Reason for Requesting Aid is required"),
  multipleyears: yup.string().required("This filed required"),
  referenceLetter: yup
    .mixed()
    .test("fileRequired", "This filed  is required", function (value) {
      return value && !!value[0]; // Check if value is defined before accessing index 0
    })
    .test("fileSize", "File size should be less than 500KB", (value) => {
      if (value) {
        const file = value[0];
        return file && file.size <= FILE_SIZE;
      }
    }),
});

const schema1 = yup.object().shape({
  amountRequested: yup.number().required("Amount Requested is required"),
  aidtype: yup.string().required("Request Aid Type is required"),
  reasonForRequesting: yup
    .string()
    .required("Reason for Requesting Aid is required"),
  multipleyears: yup.string().required("This filed required"),
  yearsofsupportisneeded: yup.string().required("This filed required"),
  referenceLetter: yup
    .mixed()
    .test("fileRequired", "This filed  is required", function (value) {
      return value && !!value[0]; // Check if value is defined before accessing index 0
    })
    .test("fileSize", "File size should be less than 500KB", (value) => {
      if (value) {
        const file = value[0];
        return file && file.size <= FILE_SIZE;
      }
    }),
});

export default function AidRequest({
  formData,
  setFormData,
  handleNext,
  handleBack,
  fileSelected,
  files,
}) {
  const [propertyOpen, setPropertyOpen] = useState("");
  const {
    handleSubmit,
    control,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(propertyOpen === "yes" ? schema1 : schema),
    defaultValues: formData,
  });

  console.log("Error ", errors);

  const onSubmit = (data) => {
    console.log("Form Data:", data);
    setFormData(data);
    handleNext();
    // Handle form submission or continue to the next step
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        p={2}
        sx={{
          display: "flex",
        }}
      >
        <Grid item md={4} sm={12}>
          <CustomAmountInput
            label="Aid Amount Requested"
            name="amountRequested"
            placeholder="Enter Amount"
            control={control}
            error={errors.amountRequested}
            className="amountInput"
          />
        </Grid>
        <Grid item md={4} sm={12}>
          <FormControl
            fullWidth
            // sx={{ width: "48%" }}
            size="small"
            className="responsive-form"
          >
            <InputLabel id="demo-select-small">
              Request Aid Type<span style={{ color: "orangered" }}> *</span>
            </InputLabel>
            <Controller
              name="aidtype"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="demo-select-small"
                  id="demo-select-small"
                  label="Request Aid Type"
                  error={!!errors.aidtype}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="School Education - Kindergarten to 8th std">
                    School Education - Kindergarten to 8th std
                  </MenuItem>
                  <MenuItem value=" School Education - 9th to 12th std">
                    School Education - 9th to 12th std
                  </MenuItem>
                  <MenuItem value="Voctional Training">
                    Voctional Training (Including ITI, Polytechnic, etc.)
                  </MenuItem>
                  <MenuItem value="Diploma">Diploma</MenuItem>
                  <MenuItem value=" Bachelor's Degree - excluding Engineering and MBBS">
                    Bachelor's Degree - excluding Engineering and MBBS
                  </MenuItem>
                  <MenuItem value="Bachelor's Degree - Engineering">
                    Bachelor's Degree - Engineering
                  </MenuItem>
                  <MenuItem value="Bachelor's Degree - MBBS">
                    Bachelor's Degree - MBBS
                  </MenuItem>
                  <MenuItem value="Master's Degree - All Streams">
                    Master's Degree - All Streams
                  </MenuItem>
                  <MenuItem value="Post Graduate Industry Certification">
                    Post Graduate Industry Certification
                  </MenuItem>
                  <MenuItem value=" Phd">Phd</MenuItem>
                  <MenuItem value="Civil Service Training - UPSC / State Civil Service, TNPSC, etc. ">
                    Civil Service Training - UPSC / State Civil Service, TNPSC,
                    etc.
                  </MenuItem>
                  <MenuItem value="Training for Competitive Exams - IAS, IPS, IFS, etc.">
                    Training for Competitive Exams - IAS, IPS, IFS, etc.
                  </MenuItem>
                  <MenuItem value="Others">Others</MenuItem>
                </Select>
              )}
            />

            {errors.aidtype && (
              <FormHelperText error>{errors.aidtype.message}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        {/* <Grid item md={12} sm={12} sx={{ marginBottom: "-15px" }}>
          <Typography
            gutterBottom
            component="div"
            className="fw-semibold text-primary"
          >
            Attachments
          </Typography>
        </Grid> */}

        <Grid item md={4} sm={12}>
          <Controller
            name="reasonForRequesting"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                id="outlined-multiline-static"
                label="Reason for Requesting Aid"
                placeholder="Enter Reason for Request here ..."
                multiline
                rows={3}
                className="responsive-form"
                // style={{ width: "690px" }}
                error={!!errors.reasonForRequesting}
                helperText={errors.reasonForRequesting?.message}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={12}>
          <Typography
            gutterBottom
            component="div"
            className="fw-semibold text-primary"
          >
            Attachments
          </Typography>
          <>
            <Typography
              sx={{ mb: 0.5 }}
              className="fs-6 text-dark-emphasis fw-normal"
            >
              Reference Letter
            </Typography>
            <div className="input-group">
              <label
                htmlFor="inputGroupFileReferenceLetter"
                className="form-control border-primary"
              >
                {files[1] ? files[1].name : "Choose a file"}
              </label>
              <Controller
                name="referenceLetter"
                control={control}
                render={({ field }) => (
                  <input
                    type="file"
                    className="form-control border-primary visually-hidden"
                    id="inputGroupFileReferenceLetter"
                    aria-describedby="inputGroupFileAddonReferenceLetter"
                    aria-label="Upload"
                    onChange={(e) => {
                      field.onChange(e.target.files);
                      fileSelected(1, e);
                    }}
                    required
                  />
                )}
              />
              <button
                className="btn btn-outline-primary"
                type="button"
                id="inputGroupFileAddonReferenceLetter"
              >
                <CloudUploadIcon />
              </button>
            </div>
            {/* Display file selection error */}
            {errors.referenceLetter && (
              <Typography variant="caption" color="error">
                {errors.referenceLetter.message}
              </Typography>
            )}
          </>
        </Grid>
        <Grid item md={4} sm={12} mt="50px">
          <FormControl variant="standard" sx={{ ml: 3 }}>
            <Controller
              name="multipleyears"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <RadioGroup
                  aria-labelledby="demo-error-radios"
                  name="general"
                  {...field}
                  sx={{ display: "flex" }}
                >
                  <Box sx={{ display: "flex" }}>
                    <FormLabel component="legend" sx={{ mr: 2 }}>
                      Is this request for multiple years?{" "}
                    </FormLabel>
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                      onClick={() => setPropertyOpen("yes")}
                    />
                    <FormControlLabel
                      value="no"
                      onClick={() => setPropertyOpen("no")}
                      control={<Radio />}
                      label="No"
                    />
                  </Box>
                </RadioGroup>
              )}
            />

            {errors.multipleyears && (
              <FormHelperText error>
                {errors.multipleyears.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>

        {propertyOpen === "yes" ? (
          <>
            <Grid item md={2} sm={12} mt="50px">
              <Typography>
                If yes, How many years of support is needed?s
              </Typography>
            </Grid>
            <Grid item md={1.5} sm={12} mt="50px">
              <Form.Group className="mb-3" controlId="formGroupPassword">
                <Controller
                  name="yearsofsupportisneeded"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Form.Control
                      {...field}
                      type="number"
                      className={`border ${
                        errors.familyCount
                          ? "border-danger"
                          : "border-primary-subtle border-2"
                      }`}
                    />
                  )}
                />
                {errors.yearsofsupportisneeded && (
                  <Typography variant="body2" color="error">
                    {errors.yearsofsupportisneeded.message}
                  </Typography>
                )}
              </Form.Group>
            </Grid>
          </>
        ) : (
          ""
        )}
      </Grid>
      <Box sx={{ display: "flex" }}>
        <Button variant="outlined" onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
          Back
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          sx={{ mt: 1, mr: 1 }}
        >
          Continue
        </Button>
      </Box>
    </>
  );
}
