import * as React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Box, Button, Typography } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Form } from 'react-bootstrap';
import CustomAmountInput from '../../helper/Form/Amount';

const FILE_SIZE = 500 * 1024;

const schema = yup.object().shape({
  fatherName: yup
    .string()
    .required('Father Name is required')
    .matches(
      /^[A-Za-z.\s]+$/,
      'This filed must contain only alphabetic characters, dots, and spaces'
    )
    .max(30, ' cannot exceed 30 characters'),

  fatherOccupation: yup.string().required("Father's Occupation is required"),
  motherName: yup
    .string()
    .required('Mother Name is required')
    .matches(
      /^[A-Za-z.\s]+$/,
      'This filed must contain only alphabetic characters, dots, and spaces'
    )
    .max(30, ' cannot exceed 30 characters'),

  motherOccupation: yup.string().required("Mother's Occupation is required"),
  familyIncome: yup.string().required('Family Income is required'),
  familyCount: yup
    .number()
    .required('Family Members count is required')
    .integer('Family Members count must be an integer')
    .positive('Family Members count must be positive'),
  proofOfIncomeFile: yup
    .mixed()
    .test('fileRequired', 'This filed is is required', function (value) {
      return value && !!value[0]; // Check if value is defined before accessing index 0
    })
    .test('fileSize', 'File size should be less than 500KB', (value) => {
      if (value) {
        const file = value[0];
        return file && file.size <= FILE_SIZE;
      }
    }),
});

export default function Step2({
  formData,
  setApplicantId,
  setFormData,
  handleNext,
  handleBack,
  fileSelected,
  files,
}) {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: formData,
  });

  const onSubmit = (data) => {
    console.log('Form Data:', data);
    setFormData(data);
    handleNext();
  };

  React.useEffect(() => {
    const myData = localStorage.getItem('myUsaDatastep1');
    if (myData) {
      const storedDataString = localStorage.getItem('myUsaDatastep1');
      const storedData = JSON.parse(storedDataString);
      setApplicantId(storedData.step1.id);
    }
  }, []);

  return (
    <>
      <Grid container spacing={2} p={2} sx={{ display: 'flex' }}>
        <Grid item md={3} sm={12}>
          <Controller
            name="fatherName"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                id="outlined-textarea"
                label={
                  <label>
                    Father's/Guardian's Name{' '}
                    <span style={{ color: 'orangered' }}>*</span>
                  </label>
                }
                placeholder="Enter Father's Name/Guardian's Name"
                size="small"
                className="responsive-form"
                error={!!errors.fatherName}
                helperText={errors.fatherName?.message}
                sx={{ width: 280, maxWidth: '100%' }}
              />
            )}
          />
        </Grid>
        <Grid item md={3} sm={12}>
          <Controller
            name="fatherOccupation"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                id="outlined-textarea"
                label={
                  <label>
                    Father's/Guardian's Occupation
                    <span style={{ color: 'orangered' }}>*</span>
                  </label>
                }
                placeholder="Enter Father's Occupation"
                size="small"
                className="responsive-form"
                error={!!errors.fatherOccupation}
                helperText={errors.fatherOccupation?.message}
                sx={{ width: 280, maxWidth: '100%' }}
              />
            )}
          />
        </Grid>
        <Grid item md={3} sm={12}>
          <Controller
            name="motherName"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                id="outlined-textarea"
                label={
                  <label>
                    Mother's Name <span style={{ color: 'orangered' }}>*</span>
                  </label>
                }
                placeholder="Enter Mother's Name"
                size="small"
                className="responsive-form"
                error={!!errors.motherName}
                helperText={errors.motherName?.message}
                sx={{ width: 280, maxWidth: '100%' }}
              />
            )}
          />
        </Grid>
        <Grid item md={3} sm={12}>
          <Controller
            name="motherOccupation"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                id="outlined-textarea"
                label={
                  <label>
                    Mother's Occupation
                    <span style={{ color: 'orangered' }}>*</span>
                  </label>
                }
                placeholder="Enter Mother's Occupation"
                size="small"
                className="responsive-form"
                error={!!errors.motherOccupation}
                helperText={errors.motherOccupation?.message}
                sx={{ width: 280, maxWidth: '100%' }}
              />
            )}
          />
        </Grid>
        {/* 2 row */}
        <Grid item md={3} sm={12}>
          {/* <Controller
            name="familyIncome"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                id="outlined-textarea"
                label={
                  <label>
                    Family Income <span style={{ color: "orangered" }}>*</span>
                  </label>
                }
                placeholder="Enter Family Income"
                size="small"
                className="responsive-form"
                error={!!errors.familyIncome}
                helperText={errors.familyIncome?.message}
                sx={{ width: 280, maxWidth: "100%" }}
              />
            )}
          /> */}

          <CustomAmountInput
            label="  Family Yearly Income"
            name="familyIncome"
            placeholder="Enter Amount"
            control={control}
            error={errors.familyIncome}
            className="amountInput"
          />
        </Grid>
        <Grid item md={2} sm={12}>
          <Typography>Living Family Members</Typography>
        </Grid>
        <Grid item md={1} sm={12} sx={{ marginTop: '-5px' }}>
          <Form.Group className="mb-3" controlId="formGroupPassword">
            <Controller
              name="familyCount"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Form.Control
                  {...field}
                  type="number"
                  className={`border ${
                    errors.familyCount
                      ? 'border-danger'
                      : 'border-primary-subtle border-2'
                  }`}
                />
              )}
            />
            {errors.familyCount && (
              <Typography variant="body2" color="error">
                {errors.familyCount.message}
              </Typography>
            )}
          </Form.Group>
        </Grid>
        <Grid item md={12} sm={12} sx={{ marginBottom: '-15px' }}>
          <Typography
            gutterBottom
            component="div"
            className="fw-semibold text-primary"
          >
            Attachments
          </Typography>
        </Grid>
        <Grid item md={4} sm={12}>
          <>
            <Typography
              sx={{ mb: 0.5 }}
              className="fs-6 text-dark-emphasis fw-normal"
            >
              Proof of Income
            </Typography>
            <div className="input-group">
              <label
                htmlFor="inputGroupFileProofOfIncome"
                className="form-control border-primary"
              >
                {files[0] ? files[0].name : 'Choose a file'}
              </label>

              <Controller
                name="proofOfIncomeFile"
                control={control}
                render={({ field }) => (
                  <input
                    type="file"
                    className="form-control border-primary visually-hidden"
                    id="inputGroupFileProofOfIncome"
                    aria-describedby="inputGroupFileAddonProofOfIncome"
                    aria-label="Upload"
                    onChange={(e) => {
                      field.onChange(e.target.files);
                      fileSelected(0, e);
                    }}
                    required
                  />
                )}
              />
              <button
                className="btn btn-outline-primary"
                type="button"
                id="inputGroupFileAddonProofOfIncome"
              >
                <CloudUploadIcon />
              </button>
            </div>
          </>
          {errors.proofOfIncomeFile && (
            <Typography variant="caption" color="error">
              {errors.proofOfIncomeFile.message}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex' }}>
        {/* <Button variant="outlined" onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
          Back
        </Button> */}
        <Button
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          sx={{ mt: 1, mr: 1 }}
        >
          Continue
        </Button>
      </Box>
    </>
  );
}
