import React from 'react';
import { useLocation } from 'react-router-dom';
import Logo from '../assets/idea-logo.png';
import ClosedLogo from '../assets/closed.png';
import '../styles/scholarshipclosed.css';

function ScholarshipClosed() {
  const location = useLocation();
  const { comments } = location.state || {};
  console.log('COMMENTS', comments);
  return (
    <div className="page-conatiner">
      <div className="header">
        <img src={Logo} alt="logo" width={'40'} height={'40'} />
        <p style={{ fontWeight: 'bold', fontSize: '18px' }}>IDEA-USA</p>
      </div>
      <div className="container">
        <img
          src={ClosedLogo}
          alt="logo"
          width="250"
          height="250"
          className="image"
        />
        <p className="text">{comments}</p>
      </div>
    </div>
  );
}

export default ScholarshipClosed;
