import React, { useState } from 'react';
import { useTransition } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import Alerts from '../helper/Alert';
import ServerErrorPage from '../ErrorPage';

const schema = yup.object().shape({
  aadharNumber: yup
    .string()
    .required('This filed is required')
    .matches(/^[0-9]+$/, 'This field must be a number')
    .max(12, 'Aadhar number must be exactly 12 digits'),
  year: yup
    .number()
    .typeError('This field must be a number')
    .required('This field is required')
    .integer('Please enter a valid integer number')
    .positive('Please enter a positive number'),
});

const EmailVerification = (props) => {
  const [pending, startTransition] = useTransition();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const API_URL = process.env.React_App_Api;

  const [alert, setAlert] = React.useState({
    open: false,
    message: '',
    severity: '',
  });
  const [errorMessage, setErrorMessage] = React.useState('');
  console.log('ERROR', errorMessage);
  const onSubmit = async (data) => {
    try {
      console.log('CALLED ON SUBMIT');
      const res = await axios.get(
        `${API_URL}/aid/acn/${data.aadharNumber}?year=${data.year}`
      );
      console.info('Lease data fetched successfully');

      props.setData(res.data);
      props.setCondition(true);
      props.setEmailverif(false);
    } catch (error) {
      console.log('ERRORS', error);
      if (error.code === 'ERR_NETWORK') {
        setErrorMessage(
          'An error occurred while fetching data. Please try again later.'
        );
      } else if (error.response.data?.statusCode === 404) {
        return setAlert({
          open: true,
          message: error.response.data?.error,
          severity: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
      } else {
        setErrorMessage(
          'An error occurred while fetching data. Please try again later.'
        );
      }
      // setTimeout(() => {
      //   window.location.reload();
      // }, 3000);
    }
  };

  const handleCloseAlert = () => {
    setAlert({
      open: false,
      message: '',
      severity: '',
    });
  };

  return (
    <>
      <Alerts
        open={alert.open}
        message={alert.message}
        severity={alert.severity}
        onClose={handleCloseAlert}
        anchorOrigin={alert.anchorOrigin}
      />
      {errorMessage ? (
        <ServerErrorPage />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div style={{ textAlign: 'center', marginTop: '30px' }}>
            <p style={{ color: 'gray' }}>
              Enter Aadhar Number & Enter Applied Previous Year{' '}
            </p>
            <div>
              <Controller
                name="aadharNumber"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={
                      <label>
                        Aadhar Number{' '}
                        <span style={{ color: 'orangered' }}>*</span>
                      </label>
                    }
                    placeholder="Enter aadharNumber"
                    size="small"
                    className="responsive-form"
                    error={!!errors.aadharNumber}
                    helperText={errors.aadharNumber?.message}
                    sx={{
                      width: 300,
                      maxWidth: '100%',
                    }}
                  />
                )}
              />
            </div>
            <div style={{ marginTop: '10px' }}>
              <Controller
                name="year"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={
                      <label>
                        Year <span style={{ color: 'orangered' }}>*</span>
                      </label>
                    }
                    placeholder="Enter a applied previous year"
                    size="small"
                    className="responsive-form"
                    error={!!errors.year}
                    helperText={errors.year?.message}
                    sx={{
                      width: 300,
                      maxWidth: '100%',
                    }}
                  />
                )}
              />
            </div>
            <button
              type="submit"
              style={{
                backgroundColor: 'green',
                borderRadius: '5px',
                border: 'none',
                color: 'white',
                margin: '10px',
                marginLeft: '30px',
                paddingLeft: '10px',
                paddingRight: '10px',
              }}
            >
              GO
            </button>
          </div>
        </form>
      )}
    </>
  );
};

export default EmailVerification;
