//Step7 for requesting the amount

import { Box, Button, FormHelperText, Grid, Typography } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import TextField from "@mui/material/TextField";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import "./form.css";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect } from "react";
import CustomAmountInput from "../../helper/Form/Amount";

const schema = yup.object().shape({
  amountRequested: yup.number().required("Amount Requested is required"),
  aidtype: yup.string().required("Request Aid Type is required"),
  reasonForRequesting: yup
    .string()
    .required("Reason for Requesting Aid is required"),
  referenceLetter: yup
    .mixed()
    .test("fileRequired", "This filed  is required", function (value) {
      return value && !!value[0];
    }),
  // multipleYears: yup.string().required("This filed is required"),
});

export default function AidRequest({
  formData,
  setFormData,
  handleNext,
  handleBack,
  data,
  files,
  fileSelected,
}) {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: formData,
  });

  const onSubmit = (data) => {
    console.log("Form Data:", data);
    setFormData(data);
    handleNext();
    // Handle form submission or continue to the next step
  };
  useEffect(() => {
    setValue(
      "amountRequested",
      data.aidinformation.amtrequested.$numberDecimal
    );
    setValue("aidtype", data.aidinformation.aidtype);
    setValue("reasonForRequesting", data.aidinformation.reasonforrequesting);
  }, []);

  return (
    <>
      <Grid
        container
        spacing={2}
        p={2}
        sx={{
          display: "flex",
        }}
      >
        <Grid item md={4} sm={12}>
          <CustomAmountInput
            label="Aid Amount Requested"
            name="amountRequested"
            placeholder="Enter Amount"
            control={control}
            error={errors.amountRequested}
            className="amountInput"
          />
          {/* <Controller
            name="amountRequested"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                id="outlined-textarea"
                label={
                  <label>
                    Aid Amount Requested{" "}
                    <span style={{ color: "orangered" }}> *</span>
                  </label>
                }
                placeholder="Enter Requested Amount"
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                error={!!errors.amountRequested}
                helperText={errors.amountRequested?.message}
                className="responsive-form"
                sx={{ width: 300, maxWidth: "100%" }}
              />
            )}
          /> */}
        </Grid>
        <Grid item md={4} sm={12}>
          <FormControl
            // sx={{ width: "48%" }}
            fullWidth
            size="small"
            className="responsive-form"
          >
            <InputLabel id="demo-select-small">
              Request Aid Type<span style={{ color: "orangered" }}> *</span>
            </InputLabel>
            <Controller
              name="aidtype"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="demo-select-small"
                  id="demo-select-small"
                  label="Request Aid Type"
                  error={!!errors.aidtype}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="School Education - Kindergarten to 8th std">
                    School Education - Kindergarten to 8th std
                  </MenuItem>
                  <MenuItem value=" School Education - 9th to 12th std">
                    School Education - 9th to 12th std
                  </MenuItem>
                  <MenuItem value="Voctional Training">
                    Voctional Training (Including ITI, Polytechnic, etc.)
                  </MenuItem>
                  <MenuItem value="Diploma">Diploma</MenuItem>
                  <MenuItem value=" Bachelor's Degree - excluding Engineering and MBBS">
                    Bachelor's Degree - excluding Engineering and MBBS
                  </MenuItem>
                  <MenuItem value="Bachelor's Degree - Engineering">
                    Bachelor's Degree - Engineering
                  </MenuItem>
                  <MenuItem value="Bachelor's Degree - MBBS">
                    Bachelor's Degree - MBBS
                  </MenuItem>
                  <MenuItem value="Master's Degree - All Streams">
                    Master's Degree - All Streams
                  </MenuItem>
                  <MenuItem value="Post Graduate Industry Certification">
                    Post Graduate Industry Certification
                  </MenuItem>
                  <MenuItem value=" Phd">Phd</MenuItem>
                  <MenuItem value="Civil Service Training - UPSC / State Civil Service, TNPSC, etc. ">
                    Civil Service Training - UPSC / State Civil Service, TNPSC,
                    etc.
                  </MenuItem>
                  <MenuItem value="Training for Competitive Exams - IAS, IPS, IFS, etc.">
                    Training for Competitive Exams - IAS, IPS, IFS, etc.
                  </MenuItem>
                  <MenuItem value="Others">Others</MenuItem>
                </Select>
              )}
            />
            <FormHelperText>{errors.aidtype?.message}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item md={4} sm={12}>
          <Controller
            name="reasonForRequesting"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                {...field}
                id="outlined-multiline-static"
                label="Reason for Requesting Aid"
                placeholder="Enter Reason for Request here ..."
                multiline
                rows={3}
                className="responsive-form"
                // style={{ width: "450px" }}
                error={!!errors.reasonForRequesting}
                helperText={errors.reasonForRequesting?.message}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={12}>
          <>
            <Typography
              // sx={{ mb: 0.5 }}
              className="fs-6 text-dark-emphasis fw-normal"
            >
              Reference Letter
            </Typography>
            <div className="input-group">
              <label
                htmlFor="inputGroupFileReferenceLetter"
                className="form-control border-primary"
              >
                {files[1] ? files[1].name : "Choose a file"}
              </label>
              <Controller
                name="referenceLetter"
                control={control}
                render={({ field }) => (
                  <input
                    type="file"
                    className="form-control border-primary visually-hidden"
                    id="inputGroupFileReferenceLetter"
                    aria-describedby="inputGroupFileAddonReferenceLetter"
                    aria-label="Upload"
                    onChange={(e) => {
                      field.onChange(e);
                      fileSelected(1, e);
                    }}
                    required
                  />
                )}
              />
              <button
                className="btn btn-outline-primary"
                type="button"
                id="inputGroupFileAddonReferenceLetter"
              >
                <CloudUploadIcon />
              </button>
            </div>
            {/* Display file selection error */}
            {errors.referenceLetter && (
              <Typography variant="caption" color="error">
                {errors.referenceLetter.message}
              </Typography>
            )}
          </>
        </Grid>
      </Grid>
      <Box sx={{ display: "flex" }}>
        <Button variant="outlined" onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
          Back
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          sx={{ mt: 1, mr: 1 }}
        >
          Continue
        </Button>
      </Box>
    </>
  );
}
