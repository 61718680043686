import React, { useState, useEffect } from 'react';
import VerticalStepper from '../AidRequest/stepper';
import AidRequestOld from '../AidRequestold/stepper';
import EmailVerification from '../AidRequestold/EmailVerification';
import ErrorBoundary from '../ErrorBoundary';
import Logo from '../assets/idea-logo.png';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { saveAs } from 'file-saver';
import MSETPDF from '../document/MSET_Educational_Scholarship_Application_Form-2.pdf';
import Axios from 'axios';
import { tableData } from '../helper/TableContent';
import { useNavigate } from 'react-router-dom';

const Main = () => {
  const navigate = useNavigate();
  const [condition, setCondition] = useState(null);
  const [emailverif, setEmailverif] = useState(false);
  const [data, setData] = useState();
  const [documentData, setDocumentData] = useState([]);
  console.log('DS', documentData);
  const handleNew = () => {
    setCondition(false);
    setEmailverif(false);
  };

  const handleDownloadPdf = () => {
    saveAs(MSETPDF, 'MSET_Educational_Scholarship_Application_Form-2.pdf');
  };
  const API_URL = process.env.React_App_Api;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Axios.get(`${API_URL}/website/listall`);
        setDocumentData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [API_URL]);

  useEffect(() => {
    if (documentData.length > 0 && documentData[0].status) {
      navigate('/closed', { state: { comments: documentData[0].comments } });
    }
  }, [documentData, navigate]);

  return (
    <div>
      {condition === null && (
        <div
          style={{
            textAlign: 'center',
            marginTop: '50px',
            marginBottom: '50px',
          }}
        >
          <img src={Logo} alt="logo" width={'60'} height={'60'} />
          <p style={{ fontWeight: 'bold', fontSize: '20px' }}>IDEA-USA</p>
          <div
            style={{
              fontSize: '14px',
              margin: '0px',
              padding: '0px',
              marginTop: '15px',
              textAlign: 'left', // Align text to the left for better readability
              marginLeft: 'auto',
              marginRight: 'auto',
              maxWidth: '600px', // Ensure the text is centered within the parent container
            }}
          >
            <p style={{ margin: '0px', padding: '0px' }}>
              <strong>Scholarship Application Pre-Requisite</strong>
            </p>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                margin: '0px',
                padding: '0px',
              }}
            >
              <span>Step 1: Please download MSET Scholarship Form</span>
              <span
                style={{ margin: '0 5px', fontSize: '20px', color: '#FB644F' }}
              >
                →
              </span>
              <button
                onClick={handleDownloadPdf}
                style={{
                  margin: '7px',
                  borderRadius: '3px',
                  backgroundColor: '#FB644F',
                  border: 'none',
                  padding: '4px',
                  color: 'white',
                  fontSize: '10px',
                  fontWeight: 'bold',
                  paddingLeft: '7px',
                  paddingRight: '7px',
                  transition: 'background-color 0.3s ease',
                }}
                onMouseOver={(e) =>
                  (e.currentTarget.style.backgroundColor = 'gray')
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.backgroundColor = '#FB644F')
                }
              >
                <CloudDownloadIcon sx={{ fontSize: '15px' }} /> PDF
              </button>
            </div>
            <p style={{ marginBottom: '5px', padding: '0px' }}>
              Step 2: Fill up the form details.
            </p>
            <p style={{ marginBottom: '5px', padding: '0px' }}>
              Step 3: Sign the form.
            </p>
            <p style={{ margin: '0px', padding: '0px' }}>
              Step 4: Scan and make it ready before proceeding further.
            </p>
            <p style={{ margin: '0px', padding: '0px', marginTop: '15px' }}>
              <strong>Note:</strong> This completed form is required to be
              uploaded in the "Acknowledgment and Declaration" section while
              filling the online application.
            </p>
          </div>
          {/* <div>
            <img src="/eligibilityImg.jpg" alt="image" width="70%vw" />
          </div> */}
          <p
            style={{ marginTop: '40px', fontWeight: 'bold', fontSize: '20px' }}
          >
            Scholarship
          </p>
          <table
            style={{
              marginTop: '20px',
              marginLeft: 'auto',
              marginRight: 'auto',
              borderCollapse: 'collapse',
            }}
          >
            <thead>
              <tr>
                {tableData.map((item, index) => (
                  <th
                    key={index}
                    style={{
                      padding: '10px',
                      border: '1px solid #ddd',
                      textAlign: 'left',
                    }}
                  >
                    {item.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                {tableData.map((item, index) => (
                  <td
                    key={index}
                    style={{ padding: '10px', border: '1px solid #ddd' }}
                  >
                    {item.amount}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
          {(documentData.length > 0 && !documentData[0].status) ||
          documentData.length === 0 ? (
            <>
              <h1 style={{ fontSize: '20px', marginTop: '50px' }}>
                {/* Have you applied for IDEA scholarship in the previous year? */}
                To proceed further, please click the button below
              </h1>
              <button
                onClick={handleNew}
                style={{
                  margin: '15px',
                  borderRadius: '3px',
                  backgroundColor: '#1789FB',
                  border: 'none',
                  fontWeight: 'bold',
                  padding: '5px',
                  color: 'white',
                  fontSize: '14px',
                  transition: 'background-color 0.3s ease',
                }}
                onMouseOver={(e) =>
                  (e.currentTarget.style.backgroundColor = '#1FA150')
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.backgroundColor = '#1789FB')
                }
              >
                Apply Now
              </button>
            </>
          ) : null}

          {/* <div style={{ display: "flex", margin: "auto" }}>
         
          </div> */}

          {/* <button
            onClick={() => setEmailverif(true)}
            style={{ border: "1px solid gray", borderRadius: "3px" }}
          >
            Yes


          </button> */}
        </div>
      )}

      {/* <ErrorBoundary fallback={<p>⚠️Something went wrong</p>}> */}
      {emailverif && (
        // <ErrorBoundary>
        <EmailVerification
          setCondition={setCondition}
          setEmailverif={setEmailverif}
          setData={setData}
        />
        // </ErrorBoundary>
      )}

      {condition === true && <AidRequestOld data={data} />}
      {condition === false && <VerticalStepper />}
    </div>
  );
};

export default Main;
