import * as React from "react";

import Grid from "@mui/material/Grid";

import TextField from "@mui/material/TextField";

import "bootstrap/dist/css/bootstrap.min.css";
import "./form.css";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import { Box, Button, Typography } from "@mui/material";

import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomDatePicker from "../../helper/Form/DatePicker";
import PhoneNumberField from "../../helper/Form/PhoneNumber";

const schema = yup.object().shape({
  firstName: yup
    .string()
    .required("First Name is required")
    .matches(
      /^[A-Za-z.\s]+$/,
      "This filed must contain only alphabetic characters, dots, and spaces"
    )
    .max(30, " cannot exceed 30 characters"),
  lastName: yup
    .string()
    .required("Last Name is required")
    .matches(
      /^[A-Za-z.\s]+$/,
      "This filed must contain only alphabetic characters, dots, and spaces"
    )
    .max(30, " cannot exceed 30 characters"),
  dob: yup.string().required("Date of Birth is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  phonenumber: yup.string().required("Contact is required"),
  // marksheetsFile: yup
  //   .mixed()
  //   .test("fileRequired", "This filed  is required", function (value) {
  //     return value && !!value[0];
  //   }),
  marksheetsFile: yup
    .mixed()
    .test("fileRequired", "This filed is required", function (value) {
      return value && !!value[0]; // Check if value is defined before accessing index 0
    }),
});
//check
export default function Step1({
  formData,
  setFormData,
  handleNext,
  data,
  files,
  fileSelected,
}) {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: formData,
  });

  const onSubmit = (data) => {
    console.log("Form Data:", data);
    setFormData(data);
    handleNext();
  };
  console.log("Error", errors);

  React.useEffect(() => {
    setValue("firstName", data.applicant.firstName);
    setValue("lastName", data.applicant.lastName);
    setValue("dob", data.applicant.dob);
    setValue("email", data.applicant.appemail);
    // setValue("phonenumber", data.applicant.phonenumber);
    setValue("phonenumber", "2345678998");
  }, []);

  return (
    <Box>
      <Grid
        container
        spacing={2}
        p={2}
        sx={{
          display: "flex",
        }}
      >
        <Grid item md={3} sm={12} sx={{ marginTop: "8px" }}>
          <Controller
            name="firstName"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={
                  <label>
                    First Name <span style={{ color: "orangered" }}>*</span>
                  </label>
                }
                placeholder="Enter First Name"
                size="small"
                className="responsive-form"
                error={!!errors.firstName}
                helperText={errors.firstName?.message}
                sx={{ width: 300, maxWidth: "100%" }}
              />
            )}
          />
        </Grid>
        <Grid item md={3} sm={12} sx={{ marginTop: "8px" }}>
          <Controller
            name="lastName"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={
                  <label>
                    Last Name <span style={{ color: "orangered" }}>*</span>
                  </label>
                }
                placeholder="Enter last Name"
                size="small"
                className="responsive-form"
                error={!!errors.lastName}
                helperText={errors.lastName?.message}
                sx={{ width: 300, maxWidth: "100%" }}
              />
            )}
          />
        </Grid>
        <Grid item md={3} sm={12}>
          <CustomDatePicker
            label="Date of Brith"
            name="dob"
            control={control}
            disableFuture={false}
            error={errors.dob}
            className="date"
          />
        </Grid>
        <Grid item md={3} sm={12} sx={{ marginTop: "8px" }}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={
                  <label>
                    Email <span style={{ color: "orangered" }}>*</span>
                  </label>
                }
                placeholder="Enter Email"
                size="small"
                className="responsive-form"
                error={!!errors.email}
                helperText={errors.email?.message}
                sx={{
                  width: 300,
                  maxWidth: "100%",
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={3} sm={12} sx={{ marginTop: "8px" }}>
          <PhoneNumberField
            control={control}
            name="phonenumber"
            label="Phone Number"
            // required={false}
            error={errors.phonenumber}
          />
        </Grid>

        <Grid item md={4} sm={12}>
          <Typography
            sx={{ mb: 0.5 }}
            className="fs-6 text-dark-emphasis fw-normal"
          >
            Upload Marksheet
          </Typography>
          <div className="input-group">
            <label
              htmlFor="inputGroupFileMarkSheet"
              className="form-control border-primary"
            >
              {files[0] ? files[0].name : "Choose a file"}
            </label>
            <Controller
              name="marksheetsFile"
              control={control}
              render={({ field }) => (
                <input
                  type="file"
                  className="visually-hidden"
                  id="inputGroupFileMarkSheet"
                  aria-describedby="inputGroupFileAddonMarkSheet"
                  aria-label="Upload"
                  onChange={(e) => {
                    field.onChange(e);
                    fileSelected(0, e);
                  }}
                  required
                />
              )}
            />
            <button
              className="btn btn-outline-primary"
              type="button"
              id="inputGroupFileAddonMarkSheet"
            >
              <CloudUploadIcon />
            </button>
          </div>
          {errors.marksheetsFile && (
            <Typography variant="caption" color="error">
              {errors.marksheetsFile.message}
            </Typography>
          )}
        </Grid>
      </Grid>

      <Button
        onClick={handleSubmit(onSubmit)}
        variant="contained"
        sx={{ mt: 1, mr: 1 }}
      >
        Continue
      </Button>
    </Box>
  );
}
