import { Container, Typography, Box } from "@mui/material";
import GifImage from "../../assets/Errors/NotFound1.png";
const NotFound = () => {
  // React.useEffect(() => {
  //   const timer = setTimeout(() => {
  //     window.location.reload(); // Reload the page after 30 seconds
  //   }, 30000);

  //   return () => clearTimeout(timer); // Clear the timer on component unmount
  // }, []);

  return (
    <Container maxWidth="md">
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <img
          src={GifImage}
          alt="GIF"
          style={{ width: "350px", height: "350px" }}
        />
      </Box>
      <Box
        sx={{
          mt: -4,
        }}>
        <Typography
          align="center"
          gutterBottom
          style={{ fontSize: "35px", fontWeight: "bold" }}>
          Page Not Found!
        </Typography>
        <Typography variant="body1" align="center" gutterBottom>
          Error Code 404
        </Typography>
      </Box>
      {/* <Container sx={{ mt: 7, textAlign: 'center' }}>
        <Button variant="contained" color="primary" to="/">
          Go to Home
        </Button>
      </Container> */}
    </Container>
  );
};

export default NotFound;
