import * as React from "react";
import PropTypes from "prop-types";
import { IMaskInput } from "react-imask";
import FormControl from "@mui/material/FormControl";
import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="+{1} (#00) 000-0000"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function PhoneNumberField({ ...props }) {
  const [values, setValues] = React.useState({
    textmask: "",
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <React.Fragment>
      <FormControl fullWidth className={props.error ? "Text-error-field" : ""}>
        <Controller
          name={props.name}
          control={props.control}
          render={({ field }) => (
            <TextField
              label={props.label || "Phone"}
              size={props.size || "small"}
              fullWidth
              value={values.textmask}
              onChange={handleChange}
              placeholder="+1 (999) 999-9999"
              name="textmask"
              InputProps={{
                inputComponent: TextMaskCustom,
              }}
              {...field}
              {...(props.error && {
                error: true,
                helperText: props.error.message,
              })}
            />
          )}
        />
      </FormControl>
    </React.Fragment>
  );
}
