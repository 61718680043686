/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import tickicons from "../assets/success1.png";
import { Box, styled, Typography, Dialog } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
export default function SuccessDialog({ ...props }) {
  const handleOk = () => {
    props.setOpen(false);
    window.location.reload();
  };

  return (
    <BootstrapDialog
      onClose={() => props.setOpen(false)}
      aria-labelledby="customized-dialog-title"
      open={props.open}>
      <div style={{ overflow: "hidden" }}>
        <DialogContent
          sx={{
            overflow: "auto",
            maxHeight: "calc(100vh - 100px)",
            padding: "30px",
          }}>
          <Box sx={{ textAlign: "center", px: 10 }}>
            <img
              src={tickicons}
              width={90}
              height={90}
              style={{ textAlign: "center" }}
            />
            <Typography sx={{ fontSize: "40px", fontWeight: "bold" }}>
              Good Job!
            </Typography>
            <Typography sx={{ fontSize: "20px", color: "gray", mt: 1 }}>
              Your Application Aid Request Submitted Successfully
            </Typography>
            <Button
              onClick={handleOk}
              variant="contained"
              color="secondary"
              sx={{
                mt: 4,
                mb: 3,
              }}
              // sx={{
              //   p: "2px ",
              //   px: "3px",
              //   fontSize: "16px",
              //   mt: 4,
              //   mb: 2,
              // }}>
            >
              ok
            </Button>
          </Box>
        </DialogContent>
      </div>
    </BootstrapDialog>
  );
}
