import * as React from 'react';
import ClockLoader from 'react-spinners/ClockLoader';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Box, Button } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import IntPhoneNumberField from '../../helper/Form/IntPhoneNumber';
import Alerts from '../../helper/Alert';
import Axios from 'axios';

const schema = yup.object().shape({
  referralName: yup
    .string()
    .required('Referral Name is required')
    .matches(
      /^[A-Za-z.\s]+$/,
      'This filed must contain only alphabetic characters, dots, and spaces'
    )
    .max(30, ' cannot exceed 30 characters'),
  referralEmail: yup.string().email('Invalid email').notRequired(),
  referralPhone: yup
    .string()
    .required('Indian Referral Mobile Number is required'),
  referralRelationship: yup
    .string()
    .required('Relationship with Referral is required')
    .matches(/^[A-Za-z\s]+$/, 'Referral Relationship must contain only letters')
    .max(30, 'Referral Relationship must be less than 30 characters'),
  // referenceLetter: yup
  //   .mixed()
  //   .test("fileRequired", "This filed  is required", function (value) {
  //     return value && !!value[0]; // Check if value is defined before accessing index 0
  //   })
  //   .test("fileSize", "File size should be less than 500KB", (value) => {
  //     if (value) {
  //       const file = value[0];
  //       return file && file.size <= FILE_SIZE;
  //     }
  //   }),
});

export default function Step3({
  formData,
  setFormData,
  handleNext,
  handleBack,
  fileSelected,
  files,
}) {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: formData,
  });

  const API_URL = process.env.React_App_Api;
  const [alert, setAlert] = React.useState({
    open: false,
    message: '',
    severity: '',
  });
  const [loading, setLoading] = React.useState(false);

  const handleCloseAlert = () => {
    setAlert({
      open: false,
      message: '',
      severity: '',
    });
  };

  const onSubmit = async (data) => {
    const payload = {
      ivname: data.referralName,
      ivphone: data.referralPhone,
      ivemail: data.referralEmail,
      relationtoapplicant: data.referralRelationship,
      usvname: formData.volunteerName,
      usvphone: formData.volunteerPhone,
      usvemail: formData.volunteerEmail,
    };
    try {
      setLoading(true);
      await Axios.post(`${API_URL}/aid/volunteerinsert`, payload).then(
        (res) => {
          console.log('RES', res);
          handleNext();
          const myData = {
            volunteersDetail: {
              id: res.data._id,
              ivname: res.data.indiavolunteer.ivname,
              ivphone: res.data.indiavolunteer.ivphone,
              ivemail: res.data.indiavolunteer.ivemail,
              relationtoapplicant: res.data.indiavolunteer.relationtoapplicant,
              usvname: res.data.usavolunteer.usvname,
              usvphone: res.data.usavolunteer.usvphone,
              email: res.data.usavolunteer.email,
            },
          };
          const storeVolunteersDetail = JSON.stringify(myData);
          localStorage.setItem('volunteersDetail', storeVolunteersDetail);
        }
      );
    } catch (error) {
      if (error.response?.data?.statusCode === 400) {
        setAlert({
          open: true,
          message: error.response.data?.error,
          severity: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
        // Reload the app after displaying the alert
        setTimeout(() => {
          window.location.reload();
        }, 3000); // Adjust the timeout duration as needed
      } else if (error.response?.status === 503) {
        setAlert({
          open: true,
          message: 'Database is down. Please try again later.',
          severity: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
        // Reload the app after displaying the alert
        setTimeout(() => {
          window.location.reload();
        }, 3000); // Adjust the timeout duration as needed
      } else if (error.message) {
        setAlert({
          open: true,
          message: error.message || 'Server Error.Please try again later',
          severity: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
        // Reload the app after displaying the alert
        setTimeout(() => {
          window.location.reload();
        }, 3000); // Adjust the timeout duration as needed
      } else {
        // Handle other errors
        setAlert({
          open: true,
          message: 'An unexpected error occurred. Please try again later.',
          severity: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
        // Reload the app after displaying the alert
        setTimeout(() => {
          window.location.reload();
        }, 3000); // Adjust the timeout duration as needed
      }
    } finally {
      setLoading(false); // Set loading state to false when form submission completes (whether success or error)
    }
    setFormData(data);
  };

  return (
    <>
      <Alerts
        open={alert.open}
        message={alert.message}
        severity={alert.severity}
        onClose={handleCloseAlert}
      />
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <ClockLoader
            color="#1976d2"
            loading={loading}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <>
          <Grid container spacing={2} p={2} sx={{ display: 'flex' }}>
            <Grid item md={3} sm={12}>
              <Controller
                name="referralName"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    id="outlined-textarea"
                    label={
                      <label>
                        Indian Referral Name{' '}
                        <span style={{ color: 'orangered' }}> *</span>
                      </label>
                    }
                    placeholder="Enter Indian Referral Name"
                    size="small"
                    className="responsive-form"
                    error={!!error}
                    helperText={error ? error.message : ''}
                    sx={{ width: 280, maxWidth: '100%' }}
                  />
                )}
              />
            </Grid>
            <Grid item md={3} sm={12}>
              <Controller
                name="referralEmail"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    id="outlined-textarea"
                    label={<label>Indian Referral Email </label>}
                    placeholder="Enter Indian Referral Email"
                    size="small"
                    className="responsive-form"
                    error={!!error}
                    helperText={error ? error.message : ''}
                    sx={{ width: 280, maxWidth: '100%' }}
                  />
                )}
              />
            </Grid>
            <Grid item md={3} sm={12}>
              <IntPhoneNumberField
                control={control}
                name="referralPhone"
                label={
                  <label>
                    Indian Referral Mobile Number
                    <span style={{ color: 'orangered' }}>*</span>
                  </label>
                }
                error={errors.referralPhone}
              />
            </Grid>
            <Grid item md={3} sm={12}>
              <Controller
                name="referralRelationship"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    id="outlined-textarea"
                    label={
                      <label>
                        Relationship with Referral{' '}
                        <span style={{ color: 'orangered' }}> *</span>
                      </label>
                    }
                    placeholder="Enter Relationship with Referral"
                    size="small"
                    className="responsive-form"
                    error={!!error}
                    helperText={error ? error.message : ''}
                    sx={{ width: 280, maxWidth: '100%' }}
                  />
                )}
              />
            </Grid>
            {/* <Grid item md={12} sm={12} sx={{ marginBottom: '-15px' }}>
          <Typography
            gutterBottom
            component="div"
            className="fw-semibold text-primary"
          >
            Attachments
          </Typography>
        </Grid>

        <Grid item md={4} sm={12}>
          <>
            <Typography
              sx={{ mb: 0.5 }}
              className="fs-6 text-dark-emphasis fw-normal"
            >
              Reference Letter
            </Typography>
            <div className="input-group">
              <label
                htmlFor="inputGroupFileReferenceLetter"
                className="form-control border-primary"
              >
                {files[1] ? files[1].name : 'Choose a file'}
              </label>
              <Controller
                name="referenceLetter"
                control={control}
                render={({ field }) => (
                  <input
                    type="file"
                    className="form-control border-primary visually-hidden"
                    id="inputGroupFileReferenceLetter"
                    aria-describedby="inputGroupFileAddonReferenceLetter"
                    aria-label="Upload"
                    onChange={(e) => {
                      field.onChange(e.target.files);
                      fileSelected(1, e);
                    }}
                    required
                  />
                )}
              />
              <button
                className="btn btn-outline-primary"
                type="button"
                id="inputGroupFileAddonReferenceLetter"
              >
                <CloudUploadIcon />
              </button>
            </div>
    
            {errors.referenceLetter && (
              <Typography variant="caption" color="error">
                {errors.referenceLetter.message}
              </Typography>
            )}
          </>
        </Grid> */}
          </Grid>
          <Box sx={{ display: 'flex' }}>
            <Button
              variant="outlined"
              onClick={handleBack}
              sx={{ mt: 1, mr: 1 }}
            >
              Back
            </Button>
            <Button
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              sx={{ mt: 1, mr: 1 }}
            >
              Save & Continue
            </Button>
          </Box>
        </>
      )}
    </>
  );
}
