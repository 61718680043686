import * as React from "react";
import PropTypes from "prop-types";
import { NumericFormat } from "react-number-format";
import { TextField, FormControl, InputAdornment } from "@mui/material";
import { Controller } from "react-hook-form";

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      fixedDecimalScale
      decimalScale={2}
    />
  );
});

NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function CustomAmountInput({ ...props }) {
  const [values, setValues] = React.useState({
    numberformat: "",
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <React.Fragment>
      <FormControl fullWidth className={props.error ? "Text-error-field" : ""}>
        <Controller
          name={props.name}
          control={props.control}
          render={({ field }) => (
            <TextField
              className={props.className}
              label={props.label || "Amount"}
              placeholder={props.placeholder || "Enter Amount"}
              value={values.numberformat}
              onChange={handleChange}
              name="numberformat"
              InputProps={{
                inputComponent: NumericFormatCustom,
                startAdornment: (
                  <InputAdornment position="start">
                    {props.currency || "Rs."}
                  </InputAdornment>
                ),
              }}
              size={props.size || "small"}
              {...field}
              {...(props.error && {
                error: true,
                helperText: props.error.message,
              })}
            />
          )}
        />
      </FormControl>
    </React.Fragment>
  );
}
