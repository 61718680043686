import React from "react";
import ClockLoader from "react-spinners/ClockLoader";
import "bootstrap/dist/css/bootstrap.min.css";

import { Row, Col } from "react-bootstrap";
import { Typography } from "@mui/material";

export default function Review({ formData, loading }) {
  function DateFormat(dateString) {
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      return dateString;
    }
    // const options = { month: 'short', day: 'numeric', year: 'numeric' };

    const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(
      date
    );
    const day = new Intl.DateTimeFormat("en-US", { day: "numeric" }).format(
      date
    );
    const year = new Intl.DateTimeFormat("en-US", { year: "numeric" }).format(
      date
    );
    return `${day}-${month}-${year}`;
  }

  function AmountFormat(amount) {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 2, // Adjust as needed
    }).format(amount);
  }

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <ClockLoader
            color="#1976d2"
            loading={loading}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <>
          <section className="border-top border-bottom p-2">
            <Typography
              sx={{
                marginBottom: "-5px",
                marginTop: "-22px",
              }}
              className="text-center"
            >
              <label
                className="fw-semibold"
                style={{
                  backgroundColor: "white",
                  color: "#ce690a",
                  fontSize: "17px",
                }}
              >
                Applicant Details
              </label>
            </Typography>
            <Row className="mt-3">
              <Col md={2} sm={6}>
                <label
                  className="text-primary fw-semibold"
                  style={{ fontSize: "17px" }}
                >
                  First Name
                </label>
                <p className="fs-6 text-muted fw-semibold ">
                  {formData.firstName}
                </p>
              </Col>
              <Col md={2} sm={6}>
                <label
                  className="text-primary fw-semibold"
                  style={{ fontSize: "17px" }}
                >
                  Last Name
                </label>
                <p className="fs-6 text-muted fw-semibold">
                  {formData.lastName}
                </p>
              </Col>
              <Col md={2} sm={6}>
                <label
                  className="text-primary fw-semibold"
                  style={{ fontSize: "17px" }}
                >
                  Aadhar Number
                </label>
                <p className="fs-6 text-muted fw-semibold text-wrap">
                  {formData.aadharNumber}
                </p>
              </Col>
              <Col md={2} sm={6}>
                <label
                  className="text-primary fw-semibold"
                  style={{ fontSize: "17px" }}
                >
                  Date of Birth
                </label>
                <p className="fs-6 text-muted fw-semibold text-wrap">
                  {DateFormat(formData.dob)}
                </p>
              </Col>
              <Col md={2} sm={6}>
                <label
                  className="text-primary fw-semibold"
                  style={{ fontSize: "17px" }}
                >
                  Email
                </label>
                <p className="fs-6 text-muted fw-semibold text-wrap">
                  {formData.email}
                </p>
              </Col>
              <Col md={2} sm={6}>
                <label
                  className="text-primary fw-semibold"
                  style={{ fontSize: "17px" }}
                >
                  Phone Number
                </label>
                <p className="fs-6 text-muted fw-semibold text-wrap">
                  {formData.phonenumber}
                </p>
              </Col>
              <Col md={2} sm={6}>
                <label
                  className="text-primary fw-semibold"
                  style={{ fontSize: "17px" }}
                >
                  Address
                </label>
                <p className="fs-6 text-muted fw-semibold">
                  {formData.address}
                </p>
              </Col>
              <Col md={2} sm={6}>
                <label
                  className="text-primary fw-semibold"
                  style={{ fontSize: "17px" }}
                >
                  City
                </label>
                <p className="fs-6 text-muted fw-semibold text-wrap">
                  {formData.city}
                </p>
              </Col>
              <Col md={2} sm={6}>
                <label
                  className="text-primary fw-semibold"
                  style={{ fontSize: "17px" }}
                >
                  State
                </label>
                <p className="fs-6 text-muted fw-semibold text-wrap">
                  {formData.state}
                </p>
              </Col>
              <Col md={2} sm={6}>
                <label
                  className="text-primary fw-semibold"
                  style={{ fontSize: "17px" }}
                >
                  District
                </label>
                <p className="fs-6 text-muted fw-semibold text-wrap">
                  {formData.district}
                </p>
              </Col>
              <Col md={2} sm={6}>
                <label
                  className="text-primary fw-semibold"
                  style={{ fontSize: "17px" }}
                >
                  Pincode
                </label>
                <p className="fs-6 text-muted fw-semibold text-wrap">
                  {formData.zip}
                </p>
              </Col>
            </Row>
          </section>
          {/* //second section */}
          <section className="border-bottom p-2">
            <Typography
              sx={{
                marginBottom: "-5px",
                marginTop: "-22px",
              }}
              className="text-center"
            >
              <label
                className="fw-semibold"
                style={{
                  backgroundColor: "white",
                  color: "#ce690a",
                  fontSize: "17px",
                }}
              >
                Parent/Guardian Details
              </label>
            </Typography>
            <Row className="mt-3">
              <Col md={2} sm={6}>
                <label
                  className="text-primary fw-semibold"
                  style={{ fontSize: "17px" }}
                >
                  Father Name
                </label>
                <p className="fs-6 text-muted fw-semibold ">
                  {formData.fatherName}
                </p>
              </Col>
              <Col md={2} sm={6}>
                <label
                  className="text-primary fw-semibold"
                  style={{ fontSize: "17px" }}
                >
                  Father Occupation
                </label>
                <p className="fs-6 text-muted fw-semibold">
                  {formData.fatherOccupation}
                </p>
              </Col>
              <Col md={2} sm={6}>
                <label
                  className="text-primary fw-semibold"
                  style={{ fontSize: "17px" }}
                >
                  Mother Name
                </label>
                <p className="fs-6 text-muted fw-semibold text-wrap">
                  {formData.motherName}
                </p>
              </Col>
              <Col md={2} sm={6}>
                <label
                  className="text-primary fw-semibold"
                  style={{ fontSize: "17px" }}
                >
                  Mother Occupation
                </label>
                <p className="fs-6 text-muted fw-semibold text-wrap">
                  {formData.motherOccupation}
                </p>
              </Col>
              <Col md={2} sm={6}>
                <label
                  className="text-primary fw-semibold"
                  style={{ fontSize: "17px" }}
                >
                  Family Income
                </label>
                <p className="fs-6 text-muted fw-semibold text-wrap">
                  {AmountFormat(formData.familyIncome)}
                </p>
              </Col>
              <Col md={2} sm={6}>
                <label
                  className="text-primary fw-semibold"
                  style={{ fontSize: "17px" }}
                >
                  Family Members
                </label>
                <p className="fs-6 text-muted fw-semibold text-wrap">
                  {formData.familyCount}
                </p>
              </Col>
            </Row>
          </section>
          {/* third section */}
          <section className="border-bottom p-2">
            <Typography
              sx={{
                marginBottom: "-5px",
                marginTop: "-22px",
              }}
              className="text-center"
            >
              <label
                className="fw-semibold"
                style={{
                  backgroundColor: "white",
                  color: "#ce690a",
                  fontSize: "17px",
                }}
              >
                Indian Reference Details
              </label>
            </Typography>
            <Row className="mt-3">
              <Col md={2} sm={6}>
                <label
                  className="text-primary fw-semibold"
                  style={{ fontSize: "17px" }}
                >
                  Referral Name
                </label>
                <p className="fs-6 text-muted fw-semibold ">
                  {formData.referralName}
                </p>
              </Col>
              <Col md={2} sm={6} style={{ width: "300px" }}>
                <label
                  className="text-primary fw-semibold"
                  style={{ fontSize: "17px" }}
                >
                  Referral Email
                </label>
                <p className="fs-6 text-muted fw-semibold">
                  {formData.referralEmail}
                </p>
              </Col>
              <Col md={2} sm={6}>
                <label
                  className="text-primary fw-semibold"
                  style={{ fontSize: "17px" }}
                >
                  Referral Phone
                </label>
                <p className="fs-6 text-muted fw-semibold text-wrap">
                  {formData.referralPhone}
                </p>
              </Col>
              <Col md={4} sm={6}>
                <label
                  className="text-primary fw-semibold"
                  style={{ fontSize: "17px" }}
                >
                  Relationship with Referral
                </label>
                <p className="fs-6 text-muted fw-semibold text-wrap">
                  {formData.referralRelationship}
                </p>
              </Col>
            </Row>
          </section>
          {/* fourth section */}
          <Typography
            sx={{
              marginBottom: "-5px",
              marginTop: "-15px",
            }}
            className="text-center"
          >
            <label
              className="fw-semibold"
              style={{
                backgroundColor: "white",
                color: "#ce690a",
                fontSize: "17px",
              }}
            >
              USA Voluteer Details
            </label>
          </Typography>
          <section className="border-bottom p-2">
            <Row className="mt-3">
              <Col md={2} sm={6}>
                <label
                  className="text-primary fw-semibold"
                  style={{ fontSize: "17px" }}
                >
                  Volunteer Name
                </label>
                <p className="fs-6 text-muted fw-semibold ">
                  {formData.volunteerName}
                </p>
              </Col>
              <Col md={2} sm={6} style={{ width: "300px" }}>
                <label
                  className="text-primary fw-semibold"
                  style={{ fontSize: "17px" }}
                >
                  Volunteer Email
                </label>
                <p className="fs-6 text-muted fw-semibold">
                  {formData.volunteerEmail}
                </p>
              </Col>
              <Col md={2} sm={6}>
                <label
                  className="text-primary fw-semibold"
                  style={{ fontSize: "17px" }}
                >
                  Volunteer Phone
                </label>
                <p className="fs-6 text-muted fw-semibold text-wrap">
                  {formData.volunteerPhone}
                </p>
              </Col>
            </Row>
          </section>
          {/* aid request */}
          <Typography
            sx={{
              marginBottom: "-5px",
              marginTop: "-15px",
            }}
            className="text-center"
          >
            <label
              className="fw-semibold"
              style={{
                backgroundColor: "white",
                color: "#ce690a",
                fontSize: "17px",
              }}
            >
              Aid Request Details
            </label>
          </Typography>
          <section className="border-bottom p-2">
            <Row className="mt-3">
              <Col md={4} sm={6}>
                <label
                  className="text-primary fw-semibold"
                  style={{ fontSize: "17px" }}
                >
                  Aid Request Amount
                </label>
                <p className="fs-6 text-muted fw-semibold ">
                  {AmountFormat(formData.amountRequested)}
                </p>
              </Col>
              <Col md={4} sm={6}>
                <label
                  className="text-primary fw-semibold"
                  style={{ fontSize: "17px" }}
                >
                  Aid Type
                </label>
                <p className="fs-6 text-muted fw-semibold">
                  {formData.aidtype}
                </p>
              </Col>
              <Col md={4} sm={6}>
                <label
                  className="text-primary fw-semibold"
                  style={{ fontSize: "17px" }}
                >
                  Aid Request Reason
                </label>
                <p className="fs-6 text-muted fw-semibold text-wrap">
                  {formData.reasonForRequesting}
                </p>
              </Col>
              <Col md={4} sm={6}>
                <label
                  className="text-primary fw-semibold"
                  style={{ fontSize: "17px" }}
                >
                  request for multiple years
                </label>
                <p className="fs-6 text-muted fw-semibold text-wrap">
                  {formData.multipleyears}
                </p>
              </Col>
              {formData.multipleyears === "yes" ? (
                <Col md={4} sm={6}>
                  <label
                    className="text-primary fw-semibold"
                    style={{ fontSize: "17px" }}
                  >
                    How many years of support is needed
                  </label>
                  <p className="fs-6 text-muted fw-semibold text-wrap">
                    {formData.yearsofsupportisneeded}
                  </p>
                </Col>
              ) : (
                ""
              )}
            </Row>
          </section>

          {/* fifth section */}
          <Typography
            sx={{
              marginBottom: "-5px",
              marginTop: "-15px",
            }}
            className="text-center"
          >
            <label
              className="fw-semibold"
              style={{
                backgroundColor: "white",
                color: "#ce690a",
                fontSize: "17px",
              }}
            >
              Institution Details
            </label>
          </Typography>
          <section className="border-bottom p-2">
            <Row className="mt-3">
              <Col md={2} sm={6}>
                <label
                  className="text-primary fw-semibold"
                  style={{ fontSize: "17px" }}
                >
                  Institution Name
                </label>
                <p className="fs-6 text-muted fw-semibold ">
                  {formData.institutionName}
                </p>
              </Col>
              <Col md={2} sm={6}>
                <label
                  className="text-primary fw-semibold"
                  style={{ fontSize: "17px" }}
                >
                  Email
                </label>
                <p className="fs-6 text-muted fw-semibold ">
                  {formData.institutionEmail}
                </p>
              </Col>
              <Col md={2} sm={6}>
                <label
                  className="text-primary fw-semibold"
                  style={{ fontSize: "17px" }}
                >
                  MobileNumber
                </label>
                <p className="fs-6 text-muted fw-semibold ">
                  {formData.institutionMobileNumber}
                </p>
              </Col>
              <Col md={2} sm={6}>
                <label
                  className="text-primary fw-semibold"
                  style={{ fontSize: "17px" }}
                >
                  Address
                </label>
                <p className="fs-6 text-muted fw-semibold">
                  {formData.institutionAddress}
                </p>
              </Col>
              <Col md={1} sm={6}>
                <label
                  className="text-primary fw-semibold"
                  style={{ fontSize: "17px" }}
                >
                  City
                </label>
                <p className="fs-6 text-muted fw-semibold text-wrap">
                  {formData.institutionCity}
                </p>
              </Col>
              <Col md={1} sm={6}>
                <label
                  className="text-primary fw-semibold"
                  style={{ fontSize: "17px" }}
                >
                  State
                </label>
                <p className="fs-6 text-muted fw-semibold text-wrap">
                  {formData.institutionState}
                </p>
              </Col>
              <Col md={1} sm={6}>
                <label
                  className="text-primary fw-semibold"
                  style={{ fontSize: "17px" }}
                >
                  Pincode
                </label>
                <p className="fs-6 text-muted fw-semibold text-wrap">
                  {formData.institutionZip}
                </p>
              </Col>
              <Col md={2} sm={6}>
                <label
                  className="text-primary fw-semibold"
                  style={{ fontSize: "17px" }}
                >
                  Beneficary Name
                </label>
                <p className="fs-6 text-muted fw-semibold text-wrap">
                  {formData.beneficiaryName}
                </p>
              </Col>
              <Col md={2} sm={6}>
                <label
                  className="text-primary fw-semibold"
                  style={{ fontSize: "17px" }}
                >
                  Beneficary Phone
                </label>
                <p className="fs-6 text-muted fw-semibold text-wrap">
                  {formData.beneficiaryPhone}
                </p>
              </Col>
            </Row>
          </section>
          <Typography
            sx={{
              marginBottom: "-5px",
              marginTop: "-15px",
            }}
            className="text-center"
          >
            <label
              className="fw-semibold"
              style={{
                backgroundColor: "white",
                color: "#ce690a",
                fontSize: "17px",
              }}
            >
              Bank DetailsSchool / College Bank Details
            </label>
          </Typography>
          <section className="border-bottom p-2">
            <Row className="mt-3">
              {/* payment */}
              <Col md={2} sm={6}>
                <label
                  className="text-primary fw-semibold"
                  style={{ fontSize: "17px" }}
                >
                  Account Number
                </label>
                <p className="fs-6 text-muted fw-semibold text-wrap">
                  {formData.accountNumber}
                </p>
              </Col>
              <Col md={1} sm={6}>
                <label
                  className="text-primary fw-semibold"
                  style={{ fontSize: "17px" }}
                >
                  IFSC Code
                </label>
                <p className="fs-6 text-muted fw-semibold text-wrap">
                  {formData.ifscCode}
                </p>
              </Col>
              <Col md={2} sm={6}>
                <label
                  className="text-primary fw-semibold"
                  style={{ fontSize: "17px" }}
                >
                  Bank Name
                </label>
                <p className="fs-6 text-muted fw-semibold text-wrap">
                  {formData.bankName}
                </p>
              </Col>
              <Col md={2} sm={6}>
                <label
                  className="text-primary fw-semibold"
                  style={{ fontSize: "17px" }}
                >
                  Branch Name
                </label>
                <p className="fs-6 text-muted fw-semibold text-wrap">
                  {formData.branchName}
                </p>
              </Col>
              <Col md={3} sm={6}>
                <label
                  className="text-primary fw-semibold"
                  style={{ fontSize: "17px" }}
                >
                  Branch Address
                </label>
                <p className="fs-6 text-muted fw-semibold text-wrap">
                  {formData.paymentAddress}
                </p>
              </Col>
            </Row>
          </section>
        </>
      )}
    </>
  );
}
