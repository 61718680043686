// }

import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Controller } from "react-hook-form";
import { FormControl } from "@mui/material";

export default function AutocompleteSelect({
  control,
  name,
  label,
  size,
  options,
  error,
}) {
  return (
    <FormControl variant="standard" fullWidth>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Autocomplete
            {...field}
            disablePortal
            size={size || "small"}
            id={name}
            options={options}
            onChange={(_, value) => field.onChange(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                error={!!error}
                helperText={error ? error.message : ""}
              />
            )}
          />
        )}
      />
    </FormControl>
  );
}
